* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}
a {
  color: white;
  text-decoration: none;
}
.start-button {
  background: #eb3349;
  background: -webkit-linear-gradient(to right, #f45c43, #eb3349);
  background: linear-gradient(to right, #f45c43, #eb3349);
  font-size: 3em !important;
  padding: 1em 2em !important;
}
.game-grid {
  height: 85vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.game-grid-item {
  margin-top: 10px;
  width: 100vw;
  /*min-height: 10vh;*/
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-container {
  max-height: 80vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.popup {
  animation-name: popanim;
  animation-duration: 0.5s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  z-index: 10;
}
.popup-body {
  animation-name: popanim;
  animation-duration: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  width: 70%;
  /* height: 40%; */
}
@keyframes popanim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.user-info {
  margin: 5px;
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.user-info > div {
  margin: 5px;
}
.user-info > button {
  margin-right: 10px;
}
.google {
  margin-right: 20px;
}
.leaderboard {
  padding-top: 10px;
  position: absolute;
  top: 0.3vh;
  right: 0;
  height: 99vh;
  width: 2vw;
  opacity: 0.5;
  background-color: white;
  -webkit-box-shadow: -4px 0px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -4px 0px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: -4px 0px 5px 0px rgba(0, 0, 0, 0.5);
  transition: all 1s ease;
}
.leaderboard-open {
  z-index: 1;
  padding-top: 10px;
  position: absolute;
  top: 0.3vh;
  right: 0;
  height: 99vh;
  width: 80vw;
  background-color: rgba(255, 255, 255, 0.8);
  transition: all 1s ease;
  -webkit-box-shadow: -4px 0px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -4px 0px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: -4px 0px 5px 0px rgba(0, 0, 0, 0.5);
}
.open-close {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #f50057;
  border: none;
  outline: none;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  -webkit-box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.5);
  left: -0.5vw;
  z-index: 99;
}
.leader-items {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  height: 100%;
  padding: 10px;
  gap: 10px;
}

.leader-item {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.current {
  background: linear-gradient(to right, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c);
}
.currentPlayer {
  border: 5px solid #90c858;
}
.currentPlayerText {
  color: #90c858;
}
.lb-content {
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 80vw;
  padding: 10px;
  padding-top: 30px;
  animation-name: lbanim;
  animation-duration: 1s;
}
.lb-content > h2 {
  color: rgb(87, 85, 85);
  letter-spacing: 1.5px;
}
@keyframes lbanim {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
