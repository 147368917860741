.flip-container {
  perspective: 1000px;
  width: auto;
  margin: 10px;
  /* height: 100%; */
}
.flip-container.flipped .flipper {
  transform: rotateY(180deg);
}
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100%; */
}
.front,
.back {
  backface-visibility: hidden;
  position: absolute;
}
.back {
  z-index: 2;
  width: 100%;
  height: 100%;
  transform: rotateY(0deg);
  background: #eb3349;
  background: -webkit-linear-gradient(to right, #f45c43, #eb3349);
  background: linear-gradient(to right, #f45c43, #eb3349);
}
.front {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateY(180deg);
}
.stats {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  color: white;
}
